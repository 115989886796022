import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-re-rendering-components",
  "date": "2020-04-30",
  "title": "React Re-rendering Components",
  "author": "admin",
  "tags": ["development", "javascript", "react"],
  "featuredImage": "feature.jpg",
  "excerpt": "React is known for providing a fast user experience by only updating the parts of the UI that have changed. In this article, we discuss how we can optimise the render performance by understanding how React decides to re-render"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What is components re-rendering ?`}</h2>
    <p>{`When looking into React's render performance, there are a few terms and concepts that can be hard to understand.`}</p>
    <p>{`Here, we will look at the most important concepts about rendering in React and how React decides to re-render a given component.`}</p>
    <p>{`And then we will find out what we can do to optimise the render performance of your React application.`}</p>
    <h3>{`Document Object Model (DOM)`}</h3>
    <p>{`In order to understand how React renders and re-renders work, we can peek into React codebase to see what happens behind the scenes of the library.`}</p>
    <p>{`The tree-like DOM represents the structure of a website, represented by HTML.
JavaScript also has a DOM, which is represented as an object where the root element is the document.`}</p>
    <p>{`You can modify the DOM with JavaScript through the DOM API that contains functions like `}<inlineCode parentName="p">{`document.write`}</inlineCode>{`, `}<inlineCode parentName="p">{`Node.appendChild`}</inlineCode>{` or `}<inlineCode parentName="p">{`Element.setAttribute`}</inlineCode>{`.`}</p>
    <h3>{`What is the Virtual document Obejct Model (VDOM)?`}</h3>
    <p>{`Then there's the Virtual DOM (or VDOM) of React, which is another abstraction layer on top of that. It consists of your React application's elements.`}</p>
    <p>{`State changes in your application will be applied to the VDOM first. If the new state of the VDOM requires a UI change,
the ReactDOM library will efficiently do this by trying to update only what needs to be updated.`}</p>
    <p>{`For example, if only the attribute of an element changes, React will only update the attribute of the HTML element by calling `}<inlineCode parentName="p">{`document.setAttribute`}</inlineCode>{` (or something similar).`}</p>
    <Message type="info" title="Diffing" content="When the VDOM gets updated, React compares it to to a previous snapshot of the VDOM and then only updates what has changed in the real DOM. 
If nothing changed, the real DOM won't be updated at all. This process of comparing the old VDOM with the new one is called **diffing**." mdxType="Message" />
    <p>{`Real DOM updates are slow because they cause an actual re-draw of the UI. React makes this more efficient by updating the smallest amount possible in the real DOM.`}</p>
    <p>{`Therefore we have to be aware of the difference between native and virtual DOM updates.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2020-04-30-react-re-rendering-components/vdom.jpg",
        "alt": "Virtual DOM"
      }}></img></p>
    <h2>{`Performance`}</h2>
    <p>{`When we talk about renders in React, we actually talk about the execution of the render function, which doesn't always imply an update of the UI.`}</p>
    <p>{`Let's see this in an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const App = () => {
  const [message, setMessage] = React.useState('');
  return (
    <>
      <Info message={message} />
      <Info />
    </>
  );
};
`}</code></pre>
    <p>{`In functional components, the execution of the whole function is the equivalent of the render function in class components.`}</p>
    <p>{`When the state changes in the higher-order component (HOC, in this case, App), the two Info components will re-render, even though the second one doesn't even receive any props.`}</p>
    <p>{`This translates to having the render function being called 3 times, but actual DOM modifications only happen once in the Info component that displays the message.`}</p>
    <p>{`React already optimises this for you, so you don't have to worry too much about performance bottlenecks of UI redraws.`}</p>
    <p>{`The execution of these render functions has two drawbacks:`}</p>
    <ul>
      <li parentName="ul">{`React has to run its diffing algorithm on each of those components to check whether it should update the UI.`}</li>
      <li parentName="ul">{`All your code in these render functions or function components will be executed again.`}</li>
    </ul>
    <p>{`The first point is arguably not that important since React manages to calculate the difference quite efficiently.
The danger lies in the code that you wrote is being executed over and over on every React render.`}</p>
    <p>{`In the example above we have a really small component tree. But imagine what happens if each node has more children and these again might have child-components.
We'll see how we can optimize this.`}</p>
    <h2>{`When does React re-render?`}</h2>
    <p>{`Above we saw what causes a re-draw of our UI, but what is calling React's render function to begin with?`}</p>
    <Message type="info" title="React re-render" content="React schedules a render every time the state of a component changes." mdxType="Message" />
    <p>{`Scheduling a render means that this doesn't happen immediately. React will try to find the best moment for this.`}</p>
    <p>{`Changing the state means that React triggers an update when we call the `}<em parentName="p">{`setState`}</em>{` function.
This doesn't only mean that the render function of the component will be called, but also that all its subsequent child-components will re-render,
regardless of whether their props have changed or not.`}</p>
    <p>{`If your application is badly structured, you might be running a lot more JavaScript than you expected because updating the parent node implies running the render function of all children.`}</p>
    <h2>{`How to optimize re-renders to prevent re-renders`}</h2>
    <p>{`A good example for inefficient re-renders is when an input field is being controlled in a higher-order component.`}</p>
    <p>{`The numbers in yellow are counting the number of times the render function of each component has been executed:`}</p>
    <h2>{`Controlling when a component should update`}</h2>
    <p>{`React provides us with a few functions to prevent these unnecessary updates.`}</p>
    <p>{`Let's have a look at them, after this, I'll show you another, more effective way of improving render performance.`}</p>
    <h2>{`React.memo`}</h2>
    <p>{`The first one, which I already gave away before, is React.memo. I already wrote a more in-depth article on this, but in summary, it's a function that prevents your React Hook components
from rendering when the props don't change.`}</p>
    <p>{`An example of this in action looks something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const InfoMemo = React.memo(({ children }) => {
  let updates = React.useRef(0);
  return (
    <div className="black-tile">
      Memo
      <Updates updates={updates.current++} />
      {children}
    </div>
  );
});
`}</code></pre>
    <p>{`There are a few more things you need to know about this before using it in production.`}</p>
    <p>{`The equivalent for React classes is using `}<inlineCode parentName="p">{`React.PureComponent`}</inlineCode>{`.`}</p>
    <h2>{`shouldComponentUpdate`}</h2>
    <p>{`This function is one of React's lifecycle functions and allows us to optimize rendering performance by telling React when to update a class component.`}</p>
    <p>{`Its arguments are the next props and the next state that the component is about to render:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`shouldComponentUpdate(nextProps, nextState) {
  // return true or false
}
`}</code></pre>
    <p>{`This function is pretty easy to use: Returning true causes React to call the render function, returning false prevents this.`}</p>
    <h2>{`Set the key attribute`}</h2>
    <p>{`In React, it is very common to do the following. Find out what's wrong with it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<div>
  {
    events.map(event =>
      <Event event={event} />
    )
  }
</div>
`}</code></pre>
    <p>{`Here I forgot to set the key attribute. Most linters will warn you about this, but why is it so important?`}</p>
    <p>{`In some cases, React relies on the key attribute for identifying components and optimizing performance.`}</p>
    <p>{`In the example above, if an event is being added to the beginning of the array, React will think that the first and all the subsequent elements have changed and will trigger a re-render
of those. We can prevent this by adding a key to the element:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<div>
  {
    events.map(event =>
      <Event event={event} key={event.id} />
    )
  }
</div>
`}</code></pre>
    <p>{`Try to avoid using the index of the array as a key and use something that identifies the content.
Keys only have to be unique among siblings.`}</p>
    <h2>{`Structure of your components`}</h2>
    <p>{`An even better way of improving re-renders is by restructuring your code a little bit.`}</p>
    <p>{`Be careful where you place your logic. If you put everything in the root component of your application, all the React.memo functions in the world won't help you to fix your performance
problems.`}</p>
    <p>{`If you place it closer to where the data is used, chances are you don't even need React.memo.`}</p>
    <p>{`Check out the optimized version of the example and type in some text:`}</p>
    <p>{`You see that even though the state updates, the other components don't re-render at all.`}</p>
    <p>{`The only change I made was to move code that handles the state into separate component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const InputSelfHandling = () => {
  const [text, setText] = React.useState('');
  return (
    <input
      value={text}
      placeholder="Write something"
      onChange={(e) => setText(e.target.value)}
    />
  );
};
`}</code></pre>
    <p>{`If you need to use the state in other parts of your application, you can do so by using React Context or alternatives like MobX and Redux.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`I hope I could give you a better understanding of how React's render mechanisms work and what you can do to get the most out of this.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      